<div class="flex md:flex-col md:w-24 md:pt-4 bg-white">

  <app-sidebar-item
    class="-md:flex-1"
    path="dashboard"
  ></app-sidebar-item>

  <app-sidebar-item
    *ngIf="isWalletConnected$ | async"
    class="-md:flex-1"
    path="my-wallet"
  ></app-sidebar-item>

  <app-sidebar-item
    class="-md:flex-1"
    path="mixer"
  ></app-sidebar-item>

</div>
