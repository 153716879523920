<div class="h-[60px] flex items-center px-[14px] bg-white border-b border-b-black/20">
  <app-logo class="mr-auto"></app-logo>

  <div
    id="accountCenter"
    [hidden]="walletIsNotConnected$ | async"
  ></div>

  <button
    class="bg-purple rounded-full py-2 px-6 text-white body-14 font-medium"
    *ngIf="walletIsNotConnectedDebounced$ | async"
    (click)="connectWallet()"
  >
    {{ 'header.connectYourWallet' | transloco }}
  </button>
</div>
