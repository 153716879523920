import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideDialogConfig } from '@ngneat/dialog';
import { provideTransloco } from '@ngneat/transloco';
import { routes } from './app.routes';
import { authTokenInterceptor } from './http/interceptors/auth-token.interceptor';
import { baseUrlInterceptor } from './http/interceptors/base-url.interceptor';
import { TranslocoHttpLoader } from './transloco-loader';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([baseUrlInterceptor, authTokenInterceptor])
    ),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es', 'ko', 'zh-CN'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideDialogConfig({
      width: 372
    })
  ],
};
