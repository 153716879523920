<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="smallLogo(); else normalLogo">
  <path d="M5.39606 13.211L25.9913 0.998661C30.3403 -1.57967 35.8471 1.58535 35.8471 6.73428V31.2824C35.8471 36.4313 30.3403 39.5964 25.9532 36.9794L5.35798 24.6513C1.07747 22.073 1.07747 15.7507 5.39606 13.211Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6289 13.7141C13.9232 15.0425 13.5703 16.5682 13.5703 18.291C13.5703 20.0139 13.9232 21.5603 14.6289 22.9302C15.3554 24.2795 16.331 25.3381 17.5557 26.1061C18.7804 26.8533 20.1296 27.227 21.6033 27.227C22.8298 27.227 23.9324 26.997 24.9111 26.537V23.4909C24.1378 23.9266 23.305 24.1445 22.4129 24.1445C21.5203 24.1445 20.6693 23.9162 19.8597 23.4595C19.071 22.9821 18.4275 22.2971 17.9293 21.4046C17.4519 20.512 17.2132 19.4742 17.2132 18.291C17.2132 17.1079 17.4519 16.0908 17.9293 15.2397C18.4275 14.3679 19.071 13.7141 19.8597 13.2782C20.6485 12.8215 21.4995 12.5932 22.4129 12.5932C23.3262 12.5932 24.1772 12.8215 24.966 13.2782C25.7548 13.7348 26.3879 14.399 26.8653 15.2708C27.3454 16.1111 27.5942 17.096 27.6115 18.2256H27.6221V26.9419H27.6125V26.9467H31.1931V9.79096H27.6125V12.2818C27.0106 11.4931 26.201 10.8392 25.1839 10.3203C24.1876 9.78059 23.0148 9.51074 21.6656 9.51074C20.1711 9.51074 18.8011 9.88437 17.5557 10.6316C16.331 11.3581 15.3554 12.3856 14.6289 13.7141Z" fill="white" style="fill:white;fill-opacity:1;"/>
</svg>

<ng-template #normalLogo>
  <svg width="133" height="38" viewBox="0 0 133 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.37067 13.2935L23.9659 1.08118C28.3149 -1.49715 33.8217 1.66786 33.8217 6.8168V31.3649C33.8217 36.5139 28.3149 39.6789 23.9278 37.062L3.33259 24.7338C-0.947919 22.1555 -0.947919 15.8332 3.37067 13.2935Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
    <path d="M109.729 27.5898C108.38 27.5898 107.166 27.3511 106.086 26.8736C105.028 26.3755 104.187 25.7112 103.564 24.881C102.942 24.0299 102.609 23.0855 102.568 22.0476H106.242C106.304 22.7741 106.647 23.3864 107.269 23.8846C107.913 24.362 108.712 24.6007 109.667 24.6007C110.663 24.6007 111.431 24.4139 111.971 24.0403C112.531 23.6459 112.812 23.1477 112.812 22.5458C112.812 21.9023 112.5 21.4249 111.877 21.1135C111.275 20.8022 110.31 20.4597 108.982 20.0861C107.695 19.7332 106.647 19.3907 105.837 19.0586C105.028 18.7265 104.322 18.2179 103.72 17.5329C103.139 16.8479 102.848 15.945 102.848 14.8241C102.848 13.9108 103.118 13.0805 103.658 12.3333C104.197 11.5652 104.965 10.9633 105.962 10.5274C106.979 10.0915 108.141 9.87354 109.449 9.87354C111.4 9.87354 112.967 10.3717 114.15 11.368C115.354 12.3436 115.998 13.6825 116.081 15.3846H112.531C112.469 14.6165 112.158 14.0042 111.597 13.5476C111.037 13.0909 110.279 12.8626 109.324 12.8626C108.39 12.8626 107.674 13.039 107.176 13.3919C106.678 13.7447 106.429 14.2118 106.429 14.793C106.429 15.2496 106.595 15.6336 106.927 15.945C107.259 16.2564 107.664 16.5054 108.141 16.6923C108.619 16.8583 109.324 17.0763 110.258 17.3461C111.504 17.6782 112.521 18.0207 113.31 18.3736C114.119 18.7057 114.815 19.2039 115.396 19.8681C115.977 20.5323 116.278 21.4145 116.299 22.5146C116.299 23.4902 116.029 24.362 115.489 25.13C114.95 25.8981 114.181 26.5 113.185 26.9359C112.21 27.3718 111.058 27.5898 109.729 27.5898Z" fill="black" style="fill:black;fill-opacity:1;"/>
    <path d="M96.0585 13.0494V22.5458C96.0585 23.1893 96.2038 23.6563 96.4944 23.9469C96.8058 24.2167 97.3247 24.3517 98.0512 24.3517H100.231V27.3096H97.4285C95.8302 27.3096 94.6055 26.9359 93.7545 26.1887C92.9034 25.4414 92.4779 24.2271 92.4779 22.5458V13.0494H90.4541V10.1538H92.4779V5.88818H96.0585V10.1538H100.231V13.0494H96.0585Z" fill="black" style="fill:black;fill-opacity:1;"/>
    <path d="M88.4243 18.3113C88.4243 18.9548 88.3828 19.536 88.2997 20.0549H75.1916C75.2954 21.4249 75.8039 22.525 76.7172 23.3553C77.6306 24.1856 78.7514 24.6007 80.0799 24.6007C81.9896 24.6007 83.3388 23.8016 84.1275 22.2033H87.9572C87.4383 23.7808 86.4939 25.0781 85.1239 26.0952C83.7747 27.0916 82.0933 27.5898 80.0799 27.5898C78.4401 27.5898 76.9663 27.2265 75.6586 26.5C74.3717 25.7528 73.3546 24.7149 72.6073 23.3864C71.8808 22.0372 71.5176 20.4804 71.5176 18.7161C71.5176 16.9517 71.8704 15.4053 72.5762 14.0769C73.3027 12.7276 74.3094 11.6898 75.5964 10.9633C76.9041 10.2368 78.3986 9.87354 80.0799 9.87354C81.699 9.87354 83.1416 10.2264 84.4078 10.9321C85.674 11.6379 86.6599 12.6342 87.3657 13.9212C88.0714 15.1874 88.4243 16.6507 88.4243 18.3113ZM84.7191 17.1904C84.6984 15.8827 84.2313 14.8345 83.318 14.0457C82.4047 13.2569 81.2734 12.8626 79.9242 12.8626C78.6995 12.8626 77.6513 13.2569 76.7795 14.0457C75.9077 14.8137 75.3888 15.862 75.2227 17.1904H84.7191Z" fill="black" style="fill:black;fill-opacity:1;"/>
    <path d="M62.296 27.5898C60.9468 27.5898 59.7325 27.3511 58.6531 26.8736C57.5945 26.3755 56.7538 25.7112 56.1311 24.881C55.5084 24.0299 55.1763 23.0855 55.1348 22.0476H58.8088C58.8711 22.7741 59.2135 23.3864 59.8363 23.8846C60.4797 24.362 61.2789 24.6007 62.2337 24.6007C63.2301 24.6007 63.9981 24.4139 64.5378 24.0403C65.0982 23.6459 65.3784 23.1477 65.3784 22.5458C65.3784 21.9023 65.0671 21.4249 64.4443 21.1135C63.8424 20.8022 62.8772 20.4597 61.5487 20.0861C60.2618 19.7332 59.2135 19.3907 58.404 19.0586C57.5945 18.7265 56.8887 18.2179 56.2868 17.5329C55.7056 16.8479 55.415 15.945 55.415 14.8241C55.415 13.9108 55.6848 13.0805 56.2245 12.3333C56.7642 11.5652 57.5322 10.9633 58.5286 10.5274C59.5457 10.0915 60.7081 9.87354 62.0158 9.87354C63.9669 9.87354 65.5341 10.3717 66.7173 11.368C67.9212 12.3436 68.5646 13.6825 68.6477 15.3846H65.0982C65.0359 14.6165 64.7246 14.0042 64.1641 13.5476C63.6037 13.0909 62.846 12.8626 61.8912 12.8626C60.9571 12.8626 60.241 13.039 59.7429 13.3919C59.2447 13.7447 58.9956 14.2118 58.9956 14.793C58.9956 15.2496 59.1617 15.6336 59.4938 15.945C59.8259 16.2564 60.2306 16.5054 60.7081 16.6923C61.1855 16.8583 61.8912 17.0763 62.8253 17.3461C64.0707 17.6782 65.0878 18.0207 65.8766 18.3736C66.6861 18.7057 67.3815 19.2039 67.9627 19.8681C68.5439 20.5323 68.8449 21.4145 68.8656 22.5146C68.8656 23.4902 68.5958 24.362 68.0561 25.13C67.5164 25.8981 66.7484 26.5 65.752 26.9359C64.7765 27.3718 63.6244 27.5898 62.296 27.5898Z" fill="black" style="fill:black;fill-opacity:1;"/>
    <path d="M45.6637 27.5898C44.3144 27.5898 43.1001 27.3511 42.0208 26.8736C40.9622 26.3755 40.1215 25.7112 39.4988 24.881C38.8761 24.0299 38.544 23.0855 38.5024 22.0476H42.1765C42.2387 22.7741 42.5812 23.3864 43.2039 23.8846C43.8474 24.362 44.6466 24.6007 45.6014 24.6007C46.5977 24.6007 47.3657 24.4139 47.9054 24.0403C48.4659 23.6459 48.7461 23.1477 48.7461 22.5458C48.7461 21.9023 48.4347 21.4249 47.812 21.1135C47.2101 20.8022 46.2449 20.4597 44.9164 20.0861C43.6295 19.7332 42.5812 19.3907 41.7717 19.0586C40.9622 18.7265 40.2564 18.2179 39.6545 17.5329C39.0733 16.8479 38.7827 15.945 38.7827 14.8241C38.7827 13.9108 39.0525 13.0805 39.5922 12.3333C40.1319 11.5652 40.8999 10.9633 41.8962 10.5274C42.9133 10.0915 44.0757 9.87354 45.3834 9.87354C47.3346 9.87354 48.9018 10.3717 50.0849 11.368C51.2888 12.3436 51.9323 13.6825 52.0153 15.3846H48.4659C48.4036 14.6165 48.0922 14.0042 47.5318 13.5476C46.9714 13.0909 46.2137 12.8626 45.2589 12.8626C44.3248 12.8626 43.6087 13.039 43.1105 13.3919C42.6124 13.7447 42.3633 14.2118 42.3633 14.793C42.3633 15.2496 42.5293 15.6336 42.8614 15.945C43.1936 16.2564 43.5983 16.5054 44.0757 16.6923C44.5531 16.8583 45.2589 17.0763 46.193 17.3461C47.4384 17.6782 48.4555 18.0207 49.2443 18.3736C50.0538 18.7057 50.7492 19.2039 51.3304 19.8681C51.9116 20.5323 52.2125 21.4145 52.2333 22.5146C52.2333 23.4902 51.9634 24.362 51.4238 25.13C50.8841 25.8981 50.1161 26.5 49.1197 26.9359C48.1441 27.3718 46.9921 27.5898 45.6637 27.5898Z" fill="black" style="fill:black;fill-opacity:1;"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 13.7966C11.8978 15.125 11.5449 16.6507 11.5449 18.3735C11.5449 20.0964 11.8978 21.6428 12.6035 23.0128C13.33 24.362 14.3056 25.4206 15.5303 26.1886C16.755 26.9359 18.1042 27.3095 19.5779 27.3095C20.8044 27.3095 21.907 27.0795 22.8857 26.6195V23.5734C22.1124 24.0092 21.2796 24.227 20.3875 24.227C19.4949 24.227 18.6439 23.9987 17.8343 23.5421C17.0456 23.0647 16.4021 22.3797 15.9039 21.4871C15.4265 20.5945 15.1878 19.5567 15.1878 18.3735C15.1878 17.1904 15.4265 16.1733 15.9039 15.3222C16.4021 14.4504 17.0456 13.7966 17.8343 13.3607C18.6231 12.904 19.4742 12.6757 20.3875 12.6757C21.3008 12.6757 22.1518 12.904 22.9406 13.3607C23.7294 13.8173 24.3625 14.4816 24.8399 15.3534C25.32 16.1937 25.5688 17.1786 25.5862 18.3081H25.5967V27.0244H25.5871V27.0293H29.1677V9.87348H25.5871V12.3643C24.9852 11.5756 24.1756 10.9217 23.1585 10.4028C22.1622 9.86311 20.9894 9.59326 19.6402 9.59326C18.1457 9.59326 16.7757 9.96689 15.5303 10.7141C14.3056 11.4406 13.33 12.4681 12.6035 13.7966Z" fill="white" style="fill:white;fill-opacity:1;"/>
  </svg>
</ng-template>
