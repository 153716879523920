import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs';
import { ApiService } from './api.service';
import { SignerService } from './signer.service';
import { Web3OnboardService } from './web3-onboard.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private web3OnboardService: Web3OnboardService,
    private signerService: SignerService,
    private apiService: ApiService
  ) {}

  authenticateWithSignature() {
    const signedTimestamp = Date.now();
    return this.signerService.sign(`Defactor Login ${signedTimestamp}`).pipe(
      switchMap(({ signature, address }) =>
        this.apiService.authSignature(signature, signedTimestamp).pipe(
          tap(({ token }) => {
            localStorage.setItem(this._tokenKey(address), token);
          })
        )
      )
    );
  }

  getAuthToken() {
    return this.web3OnboardService.signer$.pipe(
      switchMap(signer => signer.getAddress()),
      map(address => this._tokenKey(address)),
      map(tokenKey => localStorage.getItem(tokenKey))
    )
  }

  clearAuthToken() {
    return this.web3OnboardService.signer$.pipe(
      switchMap(signer => signer.getAddress()),
      map(address => this._tokenKey(address)),
      map(tokenKey => localStorage.removeItem(tokenKey))
    )
  }

  private _tokenKey(address: string) {
    return `DEFACTOR_AUTH_TOKEN_${address.toLowerCase()}`;
  }
}
