import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { SKIP_BASE_URL_INTERCEPTION } from './http/http-context-tokens';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`, {
      context: new HttpContext().set(SKIP_BASE_URL_INTERCEPTION, true),
    });
  }
}
