import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, map, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  smallLogo = signal(false);

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(
        map((event) => event.target as Window),
        startWith(window),
        untilDestroyed(this)
      )
      .subscribe((window) => {
        this.smallLogo.set(window.innerWidth < 768);
      });
  }
}
