import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { debounceTime, map } from 'rxjs';
import { Web3OnboardService } from '../../services/web3-onboard.service';
import { LogoComponent } from './logo/logo.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, LogoComponent, TranslocoPipe],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
  walletIsNotConnected$ = this.web3OnboardService.wallets$.pipe(
    map((wallets) => !wallets.length)
  );

  walletIsNotConnectedDebounced$ = this.walletIsNotConnected$.pipe(debounceTime(1000));

  constructor(private web3OnboardService: Web3OnboardService) {}

  ngAfterViewInit(): void {
    this.web3OnboardService.initialize();

    const web3OnboardShadowRoot = document.querySelector(
      'onboard-account-center'
    )?.shadowRoot;
    const stylesheet = new CSSStyleSheet();
    stylesheet.replaceSync(`
    .ac-container {
      position: relative;
      height: 60px;
    }

    .outer-container {
      min-width: 310px;
      position: absolute;
      top: 68px;
      z-index: 100;
    }`);
    web3OnboardShadowRoot?.adoptedStyleSheets.push(stylesheet);
  }

  connectWallet() {
    this.web3OnboardService.connect();
  }
}
