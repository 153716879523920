import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { SidebarItemIconComponent } from './sidebar-item-icon/sidebar-item-icon.component';

@Component({
  selector: 'app-sidebar-item',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslocoPipe,
    SidebarItemIconComponent,
  ],
  templateUrl: './sidebar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent {
  @Input({ required: true }) path!: string;
}
