import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SKIP_BASE_URL_INTERCEPTION } from '../http-context-tokens';


export const baseUrlInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.context.get(SKIP_BASE_URL_INTERCEPTION)) {
    const baseUrl = environment.apiUrl.endsWith('/')
      ? environment.apiUrl
      : environment.apiUrl + '/';

    req = req.clone({
      url: baseUrl + req.url,
    });
  }
  return next(req);
};
