import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs';
import { Web3OnboardService } from '../../services/web3-onboard.service';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarItemComponent],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  isWalletConnected$ = this.web3OnboardService.wallets$.pipe(
    map((wallets) => !!wallets.length)
  );

  constructor(private web3OnboardService: Web3OnboardService) {}
}
