import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-item-icon',
  standalone: true,
  imports: [NgIf],
  templateUrl: './sidebar-item-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemIconComponent {
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) active!: boolean;
}
