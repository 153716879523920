<ng-container *ngIf="icon === 'dashboard'">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="active">
    <path d="M4.82683 1.3335H3.56016C2.10016 1.3335 1.3335 2.10016 1.3335 3.5535V4.82016C1.3335 6.2735 2.10016 7.04016 3.5535 7.04016H4.82016C6.2735 7.04016 7.04016 6.2735 7.04016 4.82016V3.5535C7.04683 2.10016 6.28016 1.3335 4.82683 1.3335Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
    <path d="M12.4466 1.3335H11.18C9.72663 1.3335 8.95996 2.10016 8.95996 3.5535V4.82016C8.95996 6.2735 9.72663 7.04016 11.18 7.04016H12.4466C13.9 7.04016 14.6666 6.2735 14.6666 4.82016V3.5535C14.6666 2.10016 13.9 1.3335 12.4466 1.3335Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
    <path d="M12.4466 8.95361H11.18C9.72663 8.95361 8.95996 9.72028 8.95996 11.1736V12.4403C8.95996 13.8936 9.72663 14.6603 11.18 14.6603H12.4466C13.9 14.6603 14.6666 13.8936 14.6666 12.4403V11.1736C14.6666 9.72028 13.9 8.95361 12.4466 8.95361Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
    <path d="M4.82683 8.95361H3.56016C2.10016 8.95361 1.3335 9.72028 1.3335 11.1736V12.4403C1.3335 13.9003 2.10016 14.6669 3.5535 14.6669H4.82016C6.2735 14.6669 7.04016 13.9003 7.04016 12.4469V11.1803C7.04683 9.72028 6.28016 8.95361 4.82683 8.95361Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
  </svg>

  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!active">
    <path d="M6.54017 3.5512H6.54016V3.5535V4.82016C6.54016 5.46296 6.37157 5.87165 6.12161 6.12161C5.87165 6.37157 5.46296 6.54016 4.82016 6.54016H3.5535C2.9107 6.54016 2.50201 6.37157 2.25205 6.12161C2.00209 5.87165 1.8335 5.46296 1.8335 4.82016V3.5535C1.8335 2.9107 2.00208 2.5023 2.25249 2.25244C2.50308 2.00241 2.91344 1.8335 3.56016 1.8335H4.82683C5.46981 1.8335 5.87812 2.00217 6.12705 2.25165C6.37577 2.50094 6.54311 2.90883 6.54017 3.5512Z" stroke="black" style="stroke:black;stroke-opacity:1;"/>
    <path d="M11.18 1.8335H12.4466C13.0894 1.8335 13.4981 2.00209 13.7481 2.25205C13.998 2.50201 14.1666 2.9107 14.1666 3.5535V4.82016C14.1666 5.46296 13.998 5.87165 13.7481 6.12161C13.4981 6.37157 13.0894 6.54016 12.4466 6.54016H11.18C10.5372 6.54016 10.1285 6.37157 9.87851 6.12161C9.62855 5.87165 9.45996 5.46296 9.45996 4.82016V3.5535C9.45996 2.9107 9.62855 2.50201 9.87851 2.25205C10.1285 2.00209 10.5372 1.8335 11.18 1.8335Z" stroke="black" style="stroke:black;stroke-opacity:1;"/>
    <path d="M11.18 9.45361H12.4466C13.0894 9.45361 13.4981 9.62221 13.7481 9.87217C13.998 10.1221 14.1666 10.5308 14.1666 11.1736V12.4403C14.1666 13.0831 13.998 13.4918 13.7481 13.7417C13.4981 13.9917 13.0894 14.1603 12.4466 14.1603H11.18C10.5372 14.1603 10.1285 13.9917 9.87851 13.7417C9.62855 13.4918 9.45996 13.0831 9.45996 12.4403V11.1736C9.45996 10.5308 9.62855 10.1221 9.87851 9.87217C10.1285 9.62221 10.5372 9.45361 11.18 9.45361Z" stroke="black" style="stroke:black;stroke-opacity:1;"/>
    <path d="M6.54017 11.178H6.54016V11.1803V12.4469C6.54016 13.0897 6.37157 13.4984 6.12161 13.7484C5.87165 13.9984 5.46296 14.1669 4.82016 14.1669H3.5535C2.9107 14.1669 2.5023 13.9984 2.25244 13.748C2.00241 13.4974 1.8335 13.087 1.8335 12.4403V11.1736C1.8335 10.5308 2.00208 10.1224 2.25249 9.87256C2.50308 9.62252 2.91344 9.45361 3.56016 9.45361H4.82683C5.4698 9.45361 5.87783 9.62227 6.12665 9.87221C6.37546 10.1221 6.54312 10.5317 6.54017 11.178Z" stroke="black" style="stroke:black;stroke-opacity:1;"/>
  </svg>
</ng-container>

<ng-container *ngIf="icon === 'my-wallet'">

  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="active">
    <path d="M1.11004 3.98438C1.69183 3.4698 2.44205 3.18629 3.21875 3.1875H12.7812C13.5895 3.1875 14.3275 3.48854 14.89 3.98438C14.8253 3.47079 14.5753 2.99848 14.187 2.65613C13.7988 2.31377 13.2989 2.12491 12.7812 2.125H3.21875C2.7011 2.12491 2.20122 2.31377 1.81296 2.65613C1.4247 2.99848 1.17475 3.47079 1.11004 3.98438ZM1.11004 6.10938C1.69183 5.5948 2.44205 5.31129 3.21875 5.3125H12.7812C13.5895 5.3125 14.3275 5.61354 14.89 6.10938C14.8253 5.59579 14.5753 5.12348 14.187 4.78113C13.7988 4.43877 13.2989 4.24991 12.7812 4.25H3.21875C2.7011 4.24991 2.20122 4.43877 1.81296 4.78113C1.4247 5.12348 1.17475 5.59579 1.11004 6.10938ZM3.21875 6.375C2.65516 6.375 2.11466 6.59888 1.71615 6.9974C1.31763 7.39591 1.09375 7.93641 1.09375 8.5V12.75C1.09375 13.3136 1.31763 13.8541 1.71615 14.2526C2.11466 14.6511 2.65516 14.875 3.21875 14.875H12.7812C13.3448 14.875 13.8853 14.6511 14.2839 14.2526C14.6824 13.8541 14.9062 13.3136 14.9062 12.75V8.5C14.9062 7.93641 14.6824 7.39591 14.2839 6.9974C13.8853 6.59888 13.3448 6.375 12.7812 6.375H10.125C9.9841 6.375 9.84898 6.43097 9.74935 6.5306C9.64972 6.63023 9.59375 6.76535 9.59375 6.90625C9.59375 7.32894 9.42584 7.73431 9.12695 8.0332C8.82806 8.33209 8.42269 8.5 8 8.5C7.57731 8.5 7.17193 8.33209 6.87305 8.0332C6.57416 7.73431 6.40625 7.32894 6.40625 6.90625C6.40625 6.76535 6.35028 6.63023 6.25065 6.5306C6.15102 6.43097 6.0159 6.375 5.875 6.375H3.21875Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
  </svg>


  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!active">
    <path d="M14 8C14 7.60218 13.842 7.22064 13.5607 6.93934C13.2794 6.65804 12.8978 6.5 12.5 6.5H10C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5C7.46957 8.5 6.96086 8.28929 6.58579 7.91421C6.21071 7.53914 6 7.03043 6 6.5H3.5C3.10218 6.5 2.72064 6.65804 2.43934 6.93934C2.15804 7.22064 2 7.60218 2 8M14 8V12C14 12.3978 13.842 12.7794 13.5607 13.0607C13.2794 13.342 12.8978 13.5 12.5 13.5H3.5C3.10218 13.5 2.72064 13.342 2.43934 13.0607C2.15804 12.7794 2 12.3978 2 12V8M14 8V6M2 8V6M14 6C14 5.60218 13.842 5.22064 13.5607 4.93934C13.2794 4.65804 12.8978 4.5 12.5 4.5H3.5C3.10218 4.5 2.72064 4.65804 2.43934 4.93934C2.15804 5.22064 2 5.60218 2 6M14 6V4C14 3.60218 13.842 3.22064 13.5607 2.93934C13.2794 2.65804 12.8978 2.5 12.5 2.5H3.5C3.10218 2.5 2.72064 2.65804 2.43934 2.93934C2.15804 3.22064 2 3.60218 2 4V6" stroke="#151515" style="stroke:#151515;stroke:color(display-p3 0.0824 0.0824 0.0824);stroke-opacity:1;" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</ng-container>

<ng-container *ngIf="icon === 'mixer'">

  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="active">
    <path d="M1.11004 3.98438C1.69183 3.4698 2.44205 3.18629 3.21875 3.1875H12.7812C13.5895 3.1875 14.3275 3.48854 14.89 3.98438C14.8253 3.47079 14.5753 2.99848 14.187 2.65613C13.7988 2.31377 13.2989 2.12491 12.7812 2.125H3.21875C2.7011 2.12491 2.20122 2.31377 1.81296 2.65613C1.4247 2.99848 1.17475 3.47079 1.11004 3.98438ZM1.11004 6.10938C1.69183 5.5948 2.44205 5.31129 3.21875 5.3125H12.7812C13.5895 5.3125 14.3275 5.61354 14.89 6.10938C14.8253 5.59579 14.5753 5.12348 14.187 4.78113C13.7988 4.43877 13.2989 4.24991 12.7812 4.25H3.21875C2.7011 4.24991 2.20122 4.43877 1.81296 4.78113C1.4247 5.12348 1.17475 5.59579 1.11004 6.10938ZM3.21875 6.375C2.65516 6.375 2.11466 6.59888 1.71615 6.9974C1.31763 7.39591 1.09375 7.93641 1.09375 8.5V12.75C1.09375 13.3136 1.31763 13.8541 1.71615 14.2526C2.11466 14.6511 2.65516 14.875 3.21875 14.875H12.7812C13.3448 14.875 13.8853 14.6511 14.2839 14.2526C14.6824 13.8541 14.9062 13.3136 14.9062 12.75V8.5C14.9062 7.93641 14.6824 7.39591 14.2839 6.9974C13.8853 6.59888 13.3448 6.375 12.7812 6.375H10.125C9.9841 6.375 9.84898 6.43097 9.74935 6.5306C9.64972 6.63023 9.59375 6.76535 9.59375 6.90625C9.59375 7.32894 9.42584 7.73431 9.12695 8.0332C8.82806 8.33209 8.42269 8.5 8 8.5C7.57731 8.5 7.17193 8.33209 6.87305 8.0332C6.57416 7.73431 6.40625 7.32894 6.40625 6.90625C6.40625 6.76535 6.35028 6.63023 6.25065 6.5306C6.15102 6.43097 6.0159 6.375 5.875 6.375H3.21875Z" fill="#E0A225" style="fill:#E0A225;fill:color(display-p3 0.8784 0.6353 0.1451);fill-opacity:1;"/>
  </svg>


  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!active">
    <path d="M14 8C14 7.60218 13.842 7.22064 13.5607 6.93934C13.2794 6.65804 12.8978 6.5 12.5 6.5H10C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5C7.46957 8.5 6.96086 8.28929 6.58579 7.91421C6.21071 7.53914 6 7.03043 6 6.5H3.5C3.10218 6.5 2.72064 6.65804 2.43934 6.93934C2.15804 7.22064 2 7.60218 2 8M14 8V12C14 12.3978 13.842 12.7794 13.5607 13.0607C13.2794 13.342 12.8978 13.5 12.5 13.5H3.5C3.10218 13.5 2.72064 13.342 2.43934 13.0607C2.15804 12.7794 2 12.3978 2 12V8M14 8V6M2 8V6M14 6C14 5.60218 13.842 5.22064 13.5607 4.93934C13.2794 4.65804 12.8978 4.5 12.5 4.5H3.5C3.10218 4.5 2.72064 4.65804 2.43934 4.93934C2.15804 5.22064 2 5.60218 2 6M14 6V4C14 3.60218 13.842 3.22064 13.5607 2.93934C13.2794 2.65804 12.8978 2.5 12.5 2.5H3.5C3.10218 2.5 2.72064 2.65804 2.43934 2.93934C2.15804 3.22064 2 3.60218 2 4V6" stroke="#151515" style="stroke:#151515;stroke:color(display-p3 0.0824 0.0824 0.0824);stroke-opacity:1;" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</ng-container>
