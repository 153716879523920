<button
  class="h-[70px] w-full flex flex-col items-center justify-center gap-2 border-r-yellow"
  [class.md:border-r]="link.isActive"
  [routerLink]="path"
  routerLinkActive
  #link="routerLinkActive"
>

  <app-sidebar-item-icon [icon]="path" [active]="link.isActive"></app-sidebar-item-icon>

  <span
    class="body-14 -md:body-12 text-secondary"
    [class.md:font-bold]="link.isActive"
  >
    {{ ('sidebar.'+path) | transloco}}
  </span>


</button>
