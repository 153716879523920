<div class="flex flex-col h-dvh">
  <app-header></app-header>


  <div class="flex-1 flex -md:flex-col-reverse">

    <app-sidebar></app-sidebar>

    <div class="flex-1 relative">
      <div class="absolute top-0 left-0 right-0 bottom-0 overflow-auto bg-background">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>

</div>
