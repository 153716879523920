import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
  },
  {
    path: 'create-asset',
    loadChildren: () => import('./pages/create-asset/create-asset.routes'),
  },
  {
    path: 'asset-details',
    loadChildren: () => import('./pages/asset-details/asset-details.routes'),
  },
  {
    path: 'my-wallet',
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
  },
  {
    path: 'mixer',
    loadComponent: () =>
      import('./pages/mixer/mixer.component').then((mod) => mod.MixerComponent),
  },
  {
    path: 'create-mixer',
    loadComponent: () =>
      import('./pages/create-mixer/create-mixer.component').then(
        (mod) => mod.CreateMixerComponent
      ),
  },
  {
    path: 'mixer-details/:id',
    loadComponent: () =>
      import('./pages/mixer-details/mixer-details.component').then(
        (mod) => mod.MixerDetailsComponent
      ),
  },
];
