import { HttpInterceptorFn } from '@angular/common/http';

import { inject } from '@angular/core';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AUTHORIZATION_REQUIRED } from '../http-context-tokens';

export const authTokenInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.context.get(AUTHORIZATION_REQUIRED)) {
    const authService = inject(AuthService);

    return authService.getAuthToken().pipe(
      switchMap((token) => {
        if (!token) {
          return authService.authenticateWithSignature();
        } else {
          return of({ token });
        }
      }),
      switchMap(({ token }) => {
        req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });

        return next(req);
      }),
      catchError((error, observable) => {

        if (error.status === 403) {
          return authService.clearAuthToken().pipe(switchMap(() => observable));
        }

        return throwError(() => error);
      })
    );
  }

  return next(req);
};
