import { Injectable } from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { Web3OnboardService } from './web3-onboard.service';

@Injectable({
  providedIn: 'root',
})
export class SignerService {
  constructor(private web3OnboardService: Web3OnboardService) {}

  sign(message: string) {
    return this.web3OnboardService.signer$.pipe(
      switchMap((signer) =>
        forkJoin({
          address: signer.getAddress(),
          signature: signer.signMessage(message),
        })
      )
    );
  }
}
